<template>
  <MainSiteLayout v-bind="{ content }">
    <HomepageHero :content="content.hero" />
    <component
      v-for="bodySection in bodySections"
      :key="bodySection.content._key"
      :is="bodySection.component"
      :id="bodySection.content.sectionId"
      :content="bodySection.content"
      :sharedContent="bodySection.component.props['sharedContent'] && content.shared"
    />
    <FooterCta v-if="content.footerCta" :content="content.footerCta" />
  </MainSiteLayout>
</template>

<script>
export const contentQuery = `
  *[_id == $pageId][0]{
    locale,
    pencilBanner->{ message, cta },
    hero,
    bodySections[] {
      _type == 'reference' => @->,
      _type != 'reference' => @,
    },
    'shared': {
      'logoStrips': *[_type == 'logoStrip' && locale == @.locale],
    },
    footerCta->{ reviewRating, title, text, cta },
  }
`
</script>

<script setup>
import { provide } from 'vue'

import MainSiteLayout from '../../layouts/MainSiteLayout.vue'

import FooterCta from '../headerFooterSections/FooterCta.vue'
import HomepageHero from './headerFooterSections/HomepageHero.vue'

import AppPromoModule from '../bodySections/AppPromoModule.vue'
import BasicContentSection from '../bodySections/BasicContentSection/index.vue'
import CaseStudyCallout from '../bodySections/CaseStudyCallout.vue'
import GatedContentDownloadModule from '../bodySections/GatedContentDownloadModule/index.vue'
import GuidebookShowcase from './bodySections/GuidebookShowcase.vue'
import HomepageMajorSection from './bodySections/HomepageMajorSection/index.vue'
import ImagePromoBanner from '../bodySections/ImagePromoBanner.vue'
import MarketingHighlights from '../bodySections/MarketingHighlights.vue'
import PlaceholderSection from '../bodySections/PlaceholderSection.vue'
import QuoteCallout from '../bodySections/QuoteCallout.vue'
import ResourceCards from './bodySections/ResourceCards/index.vue'

const bodySectionComponentsForType = {
  'basicContentSection': BasicContentSection,
  'appPromoModule': AppPromoModule,
  'caseStudyCallout': CaseStudyCallout,
  'gatedContentDownloadModule': GatedContentDownloadModule,
  'guidebookShowcase': GuidebookShowcase,
  'marketingHighlights': MarketingHighlights,
  'homepageMajorSection': HomepageMajorSection,
  'imagePromoBanner': ImagePromoBanner,
  'quoteCallout': QuoteCallout,
  'resourceCards': ResourceCards,
}

const { breadcrumbs, content } = defineProps({
  breadcrumbs: Array,
  content: Object,
})

const bodySections = (content.bodySections ?? []).map(
  (content) => ({
    content,
    component: bodySectionComponentsForType[content._type] || PlaceholderSection,
  })
)

provide('locale', content.locale)
</script>
